<div class="contact-page-wrapper ">
  <app-page-header class="w-full" 
  [headerTitle]="'Imate pitanja? Obratite nam se s povjerenjem!'" 
  [backgroundImgColor]="'green'"></app-page-header>

  <section class="mx-auto bg-top bg-cover bg-no-repeat">
    <div class="flex flex-wrap py-20 items-center justify-center">
      <div class="mb-8 lg:mb-0 m-4 p-4">
        <a href="https://www.google.com/maps/place/Dolac+14,+51000,+Rijeka/" target="_blank">
          <div class="flex flex-col energo-contact-square justify-between py-20 shadow text-center text-white items-center">
            <img class="pb-4 md:px-0" src="https://ik.imagekit.io/energotoplinarstvo/assets/ic-location.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1648906413071" />
            <p class="font-medium text-2xl px-2"><br>Dolac 14<br>51000 Rijeka</p>
          </div>
        </a>
      </div>
      <div class="mb-8 lg:mb-0 m-4 p-4">
        <a href="tel:0800353040">
          <div class="flex flex-col energo-contact-square justify-between py-20 shadow text-center text-white items-center">
            <img class="pb-4 md:px-0" src="https://ik.imagekit.io/energotoplinarstvo/assets/ic-phone.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1648906847933" />
            <p class="font-medium text-2xl px-2">0800 353 040</p>
          </div>
        </a>
      </div>
      <div class="mb-8 lg:mb-0 m-4 p-4">
        <a href="mailto:info@energo.hr">
          <div class="flex flex-col energo-contact-square hover:energo-contact-square-hover justify-between py-20 shadow text-center text-white items-center">
            <img class="pb-4 md:px-0" src="https://ik.imagekit.io/energotoplinarstvo/assets/ic-envelope.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1648906847748" />
            <p class="font-medium text-2xl px-2">toplinarstvo@energo.hr</p>
          </div>
        </a>
      </div>
      <div class="mb-8 lg:mb-0 m-4 p-4">
        <a href="https://energo.hr" target="_blank">
          <div class="flex flex-col energo-contact-square justify-between py-20 shadow text-center text-white items-center">
            <img class="pb-4 md:px-0" src="https://ik.imagekit.io/energotoplinarstvo/assets/ic-internet.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1648906847740" />
            <p class="font-medium text-2xl px-2">www.energo.hr</p>
          </div>
        </a>
      </div>
    </div>
  </section>
</div>
