import { Injectable } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { FLContent } from 'ng-flamelink';
import { from, Observable } from 'rxjs';
import { HelpersService } from '../services/helpers/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private content: FLContent,
    private helpers: HelpersService,
  ) { }

  private readonly postsQuery = {
    schemaKey: 'post',
    orderBy: [ // NOTE: composite index is required
      { field: 'order', order: 'asc' },
      // { field: '_fl_meta_.createdDate', order: 'desc' }
    ],
    filters: [
      [ '_fl_meta_.status', '==', 'publish' ]
    ],
    populate: [
      'mainPicture'
    ]
  };

  private getPostQuery(slug: string): any {
    return {
      schemaKey: 'post',
      filters: [
        [ 'slug', '==', slug ]
      ],
      populate: [
        {
          field: 'mainPicture'
        },
        {
          field: 'gallery',
          subFields: [
            {
              field: 'image'
            }
          ]
        }
      ]
    };
  }

  private getSlugQuery(sourcePrefix: string, source: string): any {
    // console.log('getSlugQuery.source', source);
    return {
      schemaKey: 'slug',
      field: 'source',
      value: source,
      filters: [
        [ 'sourcePrefix', '==', sourcePrefix ],
        [ '_fl_meta_.status', '==', 'publish' ] // NOTE: only published slugs are active
      ]
    };
  }

  public get posts$(): Observable<any> {
    return this.helpers.safeUseScullyTransferState('posts', this.content.valueChanges<any>(this.postsQuery));
  }

  public getPostsBySlug$(slug: string): Observable<any> {
    return this.helpers.safeUseScullyTransferState('post_slug:' + slug, this.content.valueChanges<any>(this.getPostQuery(slug)));
  }

  public getSlugBySource$(sourcePrefix: string, source: string): Observable<any> {
    return this.helpers.safeUseScullyTransferState(
      `slug_${sourcePrefix}${source}`,
      from(this.content.getByField(this.getSlugQuery(sourcePrefix, source)))
    );
  }

}
