import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sss-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  @Input()
  navbarItems: { title: string, link: string }[];

  public navbar = {
    // titleImage: 'https://ik.imagekit.io/energotoplinarstvo/assets/energo-toplinarstvo-icon.svg?updatedAt=1641418659138',
    title: ``,
    // PNG is blurry
    // titleImage: 'https://ik.imagekit.io/energotoplinarstvo/assets/energo-toplinarstvo-logo.png?updatedAt=1641476086356&tr=h-300,fo-auto',
    titleImage: 'https://ik.imagekit.io/energotoplinarstvo/assets/energo-toplinarstvo-logo.svg?updatedAt=1641476393836',
    titleLink: '/',
    activeLink: '/',
    mainImage: 'https://ik.imagekit.io/energotoplinarstvo/assets/energo-toplinarstvo-logo-bold-text.svg?updatedAt=1641473669158',
    items: [],
  }

  constructor() {

  }

  ngOnInit(): void {
    this.navbar.items = this.navbarItems;
  }

}
