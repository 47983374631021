<div class="w-full bg-gray-100 px-4">
  <footer class="container flex flex-wrap mx-auto lg:flex-nowrap justify-center bg-gray-100 items-center">

    <!-- Energo part -->
    <div class="flex flex-wrap lg:flex-nowrap md:w-5/12 bg-gray-100 py-2 justify-center items-center lg:px-2">
      <div class="flex items-center py-2">
        <img class="energo-logo" src="https://ik.imagekit.io/energotoplinarstvo/tr:w-270/assets/energo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648221664783">
      </div>
      <div class="ml-4 mr-4 md:mr-0 md:py-4">
        <p class="energo-font-color mb-1 energo-font-500 energo-leading text-xs">Nositelj projekta: ENERGO d.o.o. </p>
        <p class="mb-1 energo-font-color font energo-leading text-xs">Dolac 14, 51000 Rijeka • Tel: 0800 353 040 • info@energo.hr • www.energo.hr</p>
      </div>
    </div>
  
    <!-- EU part -->
    <div class="flex flex-wrap lg:flex-nowrap md:w-8/12 py-2 bg-gray-100 justify-center items-center lg:px-4">
      <div class="flex items-center pl-4 eu-flag-wrapper py-2">
        <img class="eu-flag" src="https://ik.imagekit.io/energotoplinarstvo/tr:w-280/assets/eu-flag.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1648456518666">
      </div>
      <div class="px-4 md:py-4">
        <p class="energo-font-color mb-1 energo-font-500 energo-leading text-xs">Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj  </p>
        <p class="mb-1 energo-font-color font energo-leading text-xs">Izradu internetske stranice suﬁnancirala je Europska unija iz Europskog fonda za regionalni razvoj </p>
        <p class="mb-1 energo-font-color font energo-leading text-xs">Sva prava pridržana © Copyright by ENERGO d.o.o. 2021. </p>
        <p class="mb-1 energo-font-color font energo-leading text-xs">Odricanje od odgovornosti: Sadržaj ove internetske stranice isključivo je odgovornost ENERGO d.o.o. i ne odražava nužno gledište Europske unije
        </p>
      </div>
    </div>
  </footer>
</div>
