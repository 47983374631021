import { Component, OnInit } from '@angular/core';
import { IdleMonitorService, isScullyRunning } from '@scullyio/ng-lib';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { HomePage } from '../../models/home.page.model';
import { PagesContentService } from '../../services/pages/pages-content.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
    private content: PagesContentService,
  ) { }

  private home: HomePage;

  public status: string;
  public title: string;
  public markdownContent: string;

  public galleryItems: GalleryItem[];

  public bottomImageGrid: string[] = new Array<string>();
  public bottomImageGridImgWidth: string;

  private scullyCounter = 0

  public onReadyMarkdownContent(): void {
    // console.log('home.onReadyMarkdownContent');
    if (this.home) {
      
      // Wait for inner component to trigger
      // this.ims.fireManualMyAppReadyEvent();
      // console.log('home.fireManualMyAppReadyEvent');
      //this.ims.fireManualMyAppReadyEvent();
    }
  }

  public async ngOnInit(): Promise<void> {

    const home = await this.content.getHome();
    this.home = home;

    this.title = home.title;
    this.status = home.status;
    this.markdownContent = home.content;

    console.log('home', home);

    this.initGallery()
    this.initBottomImageGrid()
    
    this.scullyCounter++;
    this.fireManuallyAppReady()
  }

  private initGallery() {
    // Setup carousel
    const items = [];
    const urls = [
      
    ]

    for (const url of urls) {
      const item = new ImageItem({ src: url, thumb: url })
      items.push(item)
    }

    this.galleryItems = items
  }

  private initBottomImageGrid() {

    // 1536px is the container width at 2xl
    // NOTE: do not use window.innerWidth because it would not work for prerendering
    let viewportWidth = 1536
    if (!isScullyRunning()) {
      viewportWidth = Math.min(window.innerWidth, viewportWidth)
    }

    const images = [
      'https://ik.imagekit.io/energotoplinarstvo/cijevi-1.jpg',
      'https://ik.imagekit.io/energotoplinarstvo/cijevi-2.jpg',
      'https://ik.imagekit.io/energotoplinarstvo/cijevi-3.jpg',
    ]

    const imageWidth = Math.floor(viewportWidth / images.length)
    this.bottomImageGridImgWidth = `${imageWidth}px`

    const transformation = `tr=w-${imageWidth * 2},fo-auto,q-90`

    for (const image of images) {
      this.bottomImageGrid.push(`${image}?${transformation}`)
    }
  }

  public blogHomeReady(event) {
    if (event === "ready") {
      this.scullyCounter++
      this.fireManuallyAppReady()
    }
  }

  private fireManuallyAppReady() {
    if (this.scullyCounter >= 2) {
      console.log("Home scully ready");
      this.ims.fireManualMyAppReadyEvent();
    }
  }
}
