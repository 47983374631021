<!-- <div class="navbar-wrapper" >

  <nav class="flex flex-wrap flex-row items-center justify-between px-0 container">

    <div class="block flex-shrink-0 lg:hidden flex-nowrap mr-2">
      <button *ngIf="items?.length"
              class="navbar-burger flex items-center py-2 px-3 text-white focus:outline-none"
              style="background-color: #2260AE;"
              [class.bg-blue-600]="isOpenMenu"
              (click)="isOpenMenu = !isOpenMenu">
        <svg *ngIf="!isOpenMenu" class="text-white h-5 w-4" viewBox="2 0 24 18" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15.8889C0 16.6866 0.64669 17.3333 1.44442 17.3333H14.5556C15.3533 17.3333 16 16.6866 16 15.8889V15.8889C16 15.0912 15.3533 14.4445 14.5556 14.4445H1.44442C0.64669 14.4445 0 15.0912 0 15.8889V15.8889ZM0 8.6667C0 9.46445 0.646705 10.1112 1.44446 10.1112H24.5555C25.3533 10.1112 26 9.46445 26 8.6667V8.6667C26 7.86895 25.3533 7.22224 24.5555 7.22224H1.44446C0.646705 7.22224 0 7.86895 0 8.6667V8.6667ZM1.44439 0C0.646674 0 0 0.646674 0 1.44439V1.44439C0 2.2421 0.646675 2.88878 1.44439 2.88878H24.5556C25.3533 2.88878 26 2.2421 26 1.44439V1.44439C26 0.646674 25.3533 0 24.5556 0H1.44439Z" fill="white"/>
        </svg>
        <svg *ngIf="isOpenMenu" class="text-white h-5 w-4" viewBox="-2 -1 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 1.71214L15.2879 0L8.5 6.78786L1.71214 0L0 1.71214L6.78786 8.5L0 15.2879L1.71214 17L8.5 10.2121L15.2879 17L17 15.2879L10.2121 8.5L17 1.71214Z" fill="white"/>
        </svg>
      </button>
    </div>

     <div *ngIf="mainImage" class="hidden 2xl:flex flex-grow w-auto items-center justify-center">
      <a [href]="mainImageLink" target="_blank" >
        <img class="max-h-18 max-w-screen-sm" [src]="mainImage" >
      </a>
    </div> -->

    <!-- <div *ngIf="items?.length" class="navbar-menu bg-white lg:bg-transparent p-2 lg:p-0 pb-4 lg:pb-4 -mt-16 pt-20 sm:mt-2 sm:pt-2 lg:mt-0 lg:flex lg:flex-grow lg:items-center w-full lg:w-auto"
         [class.hidden]="!isOpenMenu">
      <div class="lg:ml-auto my-4" routerLinkActive="active-link">

        <ng-container *ngFor="let item of items" >
          <a [routerLink]="[item.link]"
             [ngClass]="item.isActive || activeLink === item.link ? 'py-3 px-5 mt-4 leading-none text-white text-left lg:text-right bg-secondary-blue hover:bg-secondary-blue shadow' : 'px-2 mt-4 lg:mt-0 text-gray-900 hover-text-secondary-blue'"
             class="block lg:inline-block mr-0 lg:mr-5 font-semibold rounded-lg">{{ item.title }}</a>
        </ng-container>

      </div>
    </div> -->

    <!-- <div *ngIf="mainImage" class="main-image flex flex-grow w-full flex-col p-4 lg:flex-row items-center justify-evenly text-black text-xl font-semibold -mx-4 border-t border-gray-200 bg-gray-200" >

      <a [href]="mainImageLink" target="_blank" >
        <img class="max-h-16 mt-0 max-w-full" [src]="mainImage" >
      </a>

    </div> -->
    <!-- <
  </nav>
</div> -->
<section>
  <nav class="container py-12 bg-white flex flex-row mx-auto justify-center">
    <div class="px-12 flex w-full justify-between lg:justify-between items-center">
      <a class="flex-shrink-0 no-caret" href="#">
        <img class="md:h-48 flex-shrink-0 logo-custom ml-4 md:ml-0" src="https://ik.imagekit.io/energotoplinarstvo/assets/logo-ri.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648135531757" alt="">
      </a>

      <!-- Desktop menu -->
      <ul class="hidden lg:flex-wrap lg:flex items-center lg:w-auto justify-self-center mx-auto">
        <ng-container *ngFor="let item of items; let i = index" class="flex-center">
            <li>
                <a [routerLink]="[item.link]"
                  [ngClass]="item.isActive || activeLink === item.link ? 'hover:text-yellow-600' : ''"
                  class="text-xl font-semibold text-gray-700 font-bold hover:text-yellow-600">{{ item.title }}
                </a>
              </li>        
              <li *ngIf="i != (items.length - 1)" class="text-gray-300">
              <svg class="m-2 w-4 h-4 current-fill" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
              </svg>
            </li>
        </ng-container>
      </ul>
      

      <div class="lg:hidden justify-self-end">
        <button class="navbar-burger flex items-center text-green-600 p-3"
                (click)="isOpenMenu = !isOpenMenu">
          <svg class="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Mobile menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>
      </div>
    </div>
  </nav>
  <!-- Mobile menu -->
    <div *ngIf="items?.length" class="hidden grow w-full lg:invisible navbar-menu bg-white"
      [class.hidden]="!isOpenMenu">
      <div class="lg:ml-auto my-4" routerLinkActive="active-link">
      <ng-container *ngFor="let item of items" >
        <a [routerLink]="[item.link]"
            [ngClass]="item.isActive || activeLink === item.link ? 'py-3 px-5 mt-4 leading-none text-white text-left lg:text-right bg-secondary-blue hover:bg-secondary-blue shadow' : 'px-2 mt-4 lg:mt-0 text-gray-900 hover-text-secondary-blue'"
            class="block lg:inline-block mr-0 lg:mr-5 font-semibold rounded-lg">{{ item.title }}</a>
      </ng-container>
      </div>
    </div>
</section>
