<div class="about-page-wrapper">
  <app-page-header class="w-full" 
  [headerTitle]="'O projektu'" 
  [backgroundImgColor]="'green'"></app-page-header>

  <!-- Project details: -->
  <section class="container mx-auto bg-top bg-cover bg-no-repeat mt-4">
    <div class="flex flex-wrap lg:mx-40 energo-pt-10">
  
      <div class="w-full md:w-1/2 py-4 px-10 mb-4 md:mb-0">
        <p class="font-medium text-yellow-600 text-xl mb-2 leading-relaxed">NAZIV PROJEKTA:</p>
        <p class="font-medium text-gray-700 text-base mb-2 leading-relaxed">Obnova toplinarstva grada Rijeke
          (KK.04.2.3.04)</p>
        <p class="font-medium text-yellow-600 text-xl mb-2 leading-relaxed">NOSITELJ PROJEKTA:</p>
        <p class="font-medium text-gray-700 text-base mb-2 leading-relaxed">Energo d.o.o.</p>
        <p class="font-medium text-yellow-600 text-xl mb-2 leading-relaxed">FOND:</p>
        <p class="font-medium text-gray-700 text-base mb-2 leading-relaxed">Europski fond za regionalni razvoj</p>
        <p class="font-medium text-yellow-600 text-xl mb-2 leading-relaxed">OPERATIVNI PROGRAM:</p>
        <p class="font-medium text-gray-700 text-base mb-2 leading-relaxed">Konkurentnost i kohezija 2014. - 2020.</p>
        <p class="font-medium text-yellow-600 text-xl mb-2 leading-relaxed">MEHANIZAM:</p>
        <p class="font-medium text-gray-700 text-base mb-2 leading-relaxed">Integrirana teritorijalna ulaganja (ITU)</p>
      </div>
      
      <div class="w-full md:w-1/2 py-4 px-10 mb-4 md:mb-0">
        <p class="font-medium text-yellow-600 text-xl mb-2 leading-relaxed">UKUPNA VRIJEDNOST PROJEKTA</p>
        <p class="font-medium text-gray-700 text-base mb-2 leading-relaxed">112.270.986,39 HRK</p>
        <p class="font-medium text-yellow-600 text-xl mb-2 leading-relaxed">UKUPNI PRIHVATLJIVI TROŠKOVI</p>
        <p class="font-medium text-gray-700 text-base mb-2 leading-relaxed">106.924.107,46 HRK</p>
        <p class="font-medium text-yellow-600 text-xl mb-2 leading-relaxed">EU SUFINANCIRANJE/BESPOVRATNA SREDSTVA:</p>
        <p class="font-medium text-gray-700 text-base mb-2 leading-relaxed">83.893.707,80 HRK (78,46%)</p>
        <p class="font-medium text-yellow-600 text-xl mb-2 leading-relaxed">VRIJEME TRAJANJA PROJEKTA:</p>
        <p class="font-medium text-gray-700 text-base mb-2 leading-relaxed">2021. - 2023.</p>
      </div>
    </div>
  </section>
  
  <!-- Project description and goal: -->  
  <section class="container mx-auto bg-top bg-cover bg-no-repeat">
    <div class="flex flex-row lg:mx-40"> 
      <div class="w-full py-4 px-10 mb-4 md:mb-0 max-w-4xl text-justify">
        <p class="font-bold text-yellow-600 text-2xl mb-2 leading-relaxed text-energo-green">OPIS I CILJ PROJEKTA</p>
  
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">Energo d.o.o. vodi i upravlja sustavom toplinske energije koji 
          se sastoji od 12 toplana,  </p>
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">4 kotlovnice i oko 16 km toplovoda 
          smještenih unutar administrativnih granica Grada Rijeke, </p>
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">a 
          koji toplinskom energijom opskrbljuju gotovo 10.000 korisnika.</p>
  
        <div class="w-full py-4 px- mb-4 md:mb-0">
          <p class=" font-medium text-gray-700 text-md mb-2 leading-relaxed">Necentraliziranost i veliki broj pojedinačnih sustava rezultat su gospodarske ekspanzije Rijeke </p> 
          <p class=" font-medium text-gray-700 text-md mb-2 leading-relaxed">tijekom 1960-ih i 1970-ih 
            godina kada su se za potrebe rastućeg broja stanovništva </p>
          <p class=" font-medium text-gray-700 text-md mb-2 leading-relaxed">gradila nova gradska naselja i adekvatni kvartovski sustavi 
            toplinarstva.  </p>
          <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">Danas se sustav, s obzirom na prosječnu starost 
            mreže od 50 godina, suočava s izazovima funkcioniranja  </p>
          <p class=" font-medium text-gray-700 text-md mb-2 leading-relaxed">te je 
            jasno prisutna potreba za njegovom obnovom uz praćenje suvremenih energetskih trendova i rješenja.  </p>
          <div class="w-full py-4 px- mb-4 md:mb-0">
            <p class="font-bold text-yellow-600  mb-2 leading-relaxed">Cilj je projekta „Obnova toplinarstva grada Rijeke“ 
              povećati energetsku učinkovitost toplinskog sustava pouzdanosti opskrbe toplinskom energijom u gradu Rijeci putem ulaganja u modernizaciju toplana i toplovoda uz 
              postupan prelazak na obnovljive izvore energije. </p>
            <div class="w-full py-4 px- mb-4 md:mb-0">
              <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">Postavljeni cilj će se postići povezivanjem individualnih 
                toplinskih sustava u istočnom i zapadnom dijelu grada Rijeke u jedinstvene sustave proizvodnje, distribucije i opskrbe koji 
                će jednim dijelom energiju dobivati iz kogeneracije i obnovljivih izvora energije.</p>
              <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed"> Na taj će se način postići ušteda energije, 
                smanjiti gubici distributivne mreže te doprinijeti zaštiti 
                okoliša kroz smanjenje emisije CO2, NOx i SOx. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Project scope -->
  <section class="container mx-auto bg-top bg-cover bg-no-repeat">
    <div class="flex flex-row lg:mx-40"> 
      <div class="w-full py-4 px-10 mb-4 md:mb-0 max-w-4xl text-justify">
        <p class="font-bold text-yellow-600 text-2xl mb-2 text-energo-green leading-relaxed">OBUHVAT PROJEKTA</p>
  
        <p class="font-medium text-md text-gray-700 text-base mb-2 leading-relaxed">• Obnova 7,9 km postojeće distribucije toplovodne mreže  </p>
        <p class="font-medium text-md text-gray-700 text-base mb-2 leading-relaxed">• Izgradnja 2,5 km novih spojnih toplovoda</p> 
        <p class="font-medium text-md text-gray-700 text-base mb-2 leading-relaxed">• Obnova 7 riječkih toplinskih sustava:</p>
  
        <div class="w-full py-4 px- mb-4 md:mb-0">
          <p class=" text-yellow-600 font-bold text-md text-base mb-2 leading-relaxed">TOPLINSKI SUSTAV ISTOK: obnova svih toplovoda, 
            povezivanje i rekonstrukcija toplana;  </p>
          <p class=" text-yellow-600 font-bold text-md text-base mb-2 leading-relaxed">Gornja Vežica postaje glavna toplana, Vojak rezervna, korisnici sustava V44 
            (Donja Vežica) priključuju se na toplanu Gornja Vežica</p>
          <div class="w-full py-4 px- mb-4 md:mb-0">
            <p class=" text-yellow-600 font-bold text-md text-base mb-2 leading-relaxed">TOPLINSKI SUSTAV ZAPAD: obnova svih toplovoda, 
              povezivanje i rekonstrukcija toplana; toplana Malonji 
              postaje glavna toplana,</p>
            <p class=" text-yellow-600 text-md font-bold text-base mb-2 leading-relaxed">Zamet vršna, korisnici sustava 
              Krnjevo priključuju se na toplanu Zamet   </p>
            <div class="w-full py-4 px- mb-4 md:mb-0">
              <p class=" text-yellow-600 font-bold text-md text-base mb-2 leading-relaxed">TOPLINSKI SUSTAV SRDOČI: obnova svih toplovoda i 
                rekonstrukcija toplana</p>
  
              <div class="w-full py-4 px- mb-4 md:mb-0">
                <p class="font-medium text-md text-gray-700 text-base mb-2 leading-relaxed">• ugradnja visokoučinkovitih kogeneracijskih uređaja za 
                  istovremenu proizvodnju toplinske i električne energije</p>
                <p class="font-medium  text-md text-gray-700 text-base mb-2 leading-relaxed">• ugradnja toplinskih spremnika </p>
                <p class="font-medium   text-md text-gray-700 text-base mb-2 leading-relaxed">• ugradnja solarnih kolektora  </p>
                <p class="font-medium   text-md text-gray-700 text-base mb-2 leading-relaxed">•  potpuna automatizacija svih sustava  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Project expected results -->
  <section class="container pb-8 mx-auto mb-8 bg-top bg-cover bg-no-repeat">
    <div class="flex flex-wrap lg:mx-40">
      <div class="w-full px-10 mb-4 md:mb-0 max-w-4xl text-justify">
        <p class="font-bold text-yellow-600 text-2xl mb-2 leading-relaxed text-energo-green">OČEKIVANI REZULTATI</p>
  
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">• Smanjenje emisije CO2 i uklanjanje SO2 iz proizvodnje</p>
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">• Unaprjeđenje učinkovitosti zbog optimizacije u proizvodnji 
          i manjih gubitaka distribucije</p>
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">• Prelazak svih proizvodnih pogona toplana na prirodni plin 
          kao glavni energent</p>
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">• Proizvodnja više od 50% toplinske energije iz kogeneracije 
          i obnovljivih izvora</p>
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">• Priprema sustava za sve veću integraciju obnovljivih 
          izvora energije u budućnosti</p>
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">• 24-satni rad svih toplana u sustavu</p>
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">• Potpuna obnova navedenih sustava bez povećanja 
          troškova za korisnike ili opterećenja proračuna Grada</p>
      </div>
    </div>
  </section>

  <section class="container pb-8 mx-auto mb-8 bg-top bg-cover bg-no-repeat">
    <div class="flex flex-wrap lg:mx-40">
      <div class="w-full px-10 mb-4 md:mb-0 max-w-4xl text-justify">
        <p class="font-medium text-gray-700 text-md mb-2 leading-relaxed">• Preuzmite letak projekta</p>
        <a [href]="publicationUrl" target="_blank" >
          <div class="text-sm text-yellow-600 font-bold px-2" >
            Preuzmite &rarr;
          </div>
        </a>
      </div>
    </div>
  </section>
</div>