<!-- Aglomeracija Sinj -->
<!-- <div class="leading-bullets-wrapper">
  
  <section class="pt-8 md:pt-20 pb-8">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap -mx-4">
        <div class="w-full md:w-1/2 lg:w-1/4 mb-6 px-12">
          <div class="text-center">
            <img class="inline-block" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-icon.svg?updatedAt=1641418659138">
            <h4 class="mb-3 text-blue-900 font-semibold font-heading">UKUPNA VRIJEDNOST PROJEKTA</h4>
            <p class="text-blueGray-400 leading-loose">378.337.251,63 HRK</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 mb-6 px-12">
          <div class="text-center">
            <img class="inline-block" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-icon.svg?updatedAt=1641418659138">
            <h4 class="mb-3 text-blue-900 font-semibold font-heading">BESPOVRATNA SREDSTVA OSIGURANA IZ KOHEZIJSKOG FONDA</h4>
            <p class="text-blueGray-400 leading-loose">211.610.086,23 HRK</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-12">
          <div class="text-center">
            <img class="inline-block" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-icon.svg?updatedAt=1641418659138">
            <h4 class="mb-3 text-blue-900 font-semibold font-heading">NACIONALNA SREDSTVA FINANCIRANJA</h4>
            <p class="text-blueGray-400 leading-loose">91.059.715,07 HRK</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-12">
          <div class="text-center">
            <img class="inline-block" src="https://ik.imagekit.io/aglomeracijasinj/assets/aglomeracija-sinj-icon.svg?updatedAt=1641418659138">
            <h4 class="mb-3 text-blue-900 font-semibold font-heading">RAZDOBLJE PROVEDBE PROJEKTA</h4>
            <p class="text-blueGray-400 leading-loose">2020. - 2024.</p>
          </div>

        </div>
      </div>
    </div>
  </section>
</div> -->

<section class="w-full pt-8 pb- bg-gray-100">
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap items-center">
      <div class="w-full md:w-1/2 lg:w-1/4 mb-6 px-2">
        <div class="text-center">

          <h4 class="mb-4 text-yellow-600 font-semibold text-lg font-heading">NAZIV PROJEKTA</h4>
          <p class="text-gray-700 text-base font-bold leading-loose">Obnova toplinarstva grada Rijeke</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 mb-6 px-12">
        <div class="text-center">

          <h4 class="mb-3 text-yellow-600 font-semibold text-lg font-heading">NOSITELJ PROJEKTA</h4>
          <p class="text-gray-700 font-bold text-base leading-loose">Energo d.o.o.</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 mb-6 px-12">
        <div class="text-center">

          <h4 class="mb-3 text-yellow-600 font-semibold text-lg text-lg font-heading">FOND </h4>
          <p class="text-gray-700 font-bold text-base leading-loose">Europski fond za regionalni razvoj</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 mb-6 px-12">
        <div class="text-center">

          <h4 class="mb-2 text-yellow-600 font-semibold text-lg text-lgfont-heading">OPERATIVNI PROGRAM I MEHANIZAM</h4>
          <p class="text-gray-700 font-bold  text-base leading-loose">Konkurentnost i kohezija 2014. - 2020. i Integrirana teritorijalna ulaganja (ITU)</p>
        </div>
      </div>
    </div>
  </div>
</section>