<section class="pt-2 pb-2 bg-gray-100">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2 lg:w-1/4 mb-2 md:mb-6 px-12">
          <div class="flex flex-col items-center text-center">
            <img src="https://ik.imagekit.io/energotoplinarstvo/assets/number-one.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1649684262064">
            <h4 class="mb-4 text-yellow-600 font-semibold font-heading">UKUPNA VRIJEDNOST<br>PROJEKTA</h4>
            <p class="text-gray-700 font-bold leading-loose">112.270.986,39 HRK</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 mb-2 md:mb-6 px-12">
          <div class="flex flex-col items-center text-center">
            <img src="https://ik.imagekit.io/energotoplinarstvo/assets/number-two.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1649684261999">
            <h4 class="mb-3 text-yellow-600 font-semibold font-heading">UKUPNI PRIHVATLJIVI<br>TROŠKOVI</h4>
            <p class="text-gray-700 font-bold leading-loose">106.924.107,46 HRK</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 mb-2 md:mb-6 px-12">
          <div class="flex flex-col items-center text-center">
            <img src="https://ik.imagekit.io/energotoplinarstvo/assets/number-three.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1649074190753">
            <h4 class="mb-3 text-yellow-600 font-semibold font-heading">EU SREDSTVA<br>(Fond za regionalni razvoj)  </h4>
            <p class="text-gray-700 font-bold leading-loose">83.893.707,80 HRK (78,46%)</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/4 mb-2 md:mb-6 px-12">
          <div class="flex flex-col items-center text-center">
            <img src="https://ik.imagekit.io/energotoplinarstvo/assets/number-four.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1649074191141">
            <h4 class="mb-3 text-yellow-600 font-semibold font-heading">RAZDOBLJE PROVEDBE<br>PROJEKTA</h4>
            <p class="text-gray-700 font-bold  leading-loose">2021. - 2023.</p>
          </div>
        </div>
      </div>
    </div>
  </section>