import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IdleMonitorService } from '@scullyio/ng-lib';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { BlogService } from '../../blog/blog.service';
import { PagesContentService } from '../../services/pages/pages-content.service';

@UntilDestroy()
@Component({
  selector: 'app-home-page-blog',
  templateUrl: './home-page-blog.component.html',
  styleUrls: ['./home-page-blog.component.scss']
})
export class HomePageBlogComponent implements OnInit {

  constructor(private blogService: BlogService,
    private content: PagesContentService,
    private ims: IdleMonitorService) { }
  
  public posts$ = this.blogService.posts$;
  public homePagePosts$ = new Subject<any>();
  private howManyMarkdownContentsIsReady = 0;
  public moment = moment;
  
  private title = "Naslovnica blog";
  private firedIms = false;


  @Output() componentScullyReady: EventEmitter<string> = new EventEmitter();

  public async ngOnInit(): Promise<void> {

    // 1. wait for all blog posts to be loaded from Flamelink (Firestore)
    this.posts$.pipe(untilDestroyed(this)).subscribe(async posts => {

      console.log('ngOnInit.blog.posts', posts);
      // 2. wait for blog page to be loaded from Flamelink (Firestore)
      // NOTE(istep): Do NOT remove this line.
      let blog = await this.content.getBlog();

      // 3. Filter posts. Use only isCitizenNews === false.
      let arr = posts.filter(el => el.isAtHomePage === true);

      // 4. Show only last 1 items. 6. (Change order in firestore for date sort.)
      arr = arr.slice(0, 3);
      this.homePagePosts$.next(arr);
      console.log("Filtered arr" + arr);

      // 5. Propagate to parent control that this control is scully ready.
      this.componentScullyReady.emit("ready");
    });
  }

}
