import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sss-eu-visibility-main',
  templateUrl: './eu-visibility-main.component.html',
  styleUrls: ['./eu-visibility-main.component.scss']
})
export class EuVisibilityMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
