<section>
  <div class="relative bg-blue-900 pt-20 pb-24 lg:py-24 overflow-hidden">
    <div class="container flex flex-wrap mx-auto px-4">
        <div class="w-full lg:w-1/2 px-4 flex items-center">
          <div class="w-full text-center lg:text-left">
            <div class="relative max-w-md mx-auto lg:mx-0">
              <h2 class="mb-3 text-4xl text-white lg:text-5xl font-bold font-heading">
                <span>Modernizacija toplana i toplovoda u gradu Rijeci</span>
              </h2>
            </div>
            <div class="relative max-w-sm mx-auto lg:mx-0">
              <p class="mb-6 text-white  leading-loose">Cilj je projekta „Obnova toplinarstva grada Rijeke“ povećati energetsku učinkovitost toplinskog sustava i pouzdanosti opskrbe toplinskom energijom u gradu Rijeci putem ulaganja u modernizaciju toplana i toplovoda uz postupan prelazak na obnovljive izvore energije.</p>
              <div>
                <a class="inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 leading-loose bg-yellow-600 hover:bg-yellow-700 text-white font-semibold rounded-l-xl rounded-t-xl transition duration-200" [routerLink]="['/informacije-o-projektu']">Više o projektu</a>
                <a class="inline-block w-full lg:w-auto py-2 px-6 leading-loose font-semibold bg-white text-yellow-600 rounded-l-xl rounded-t-xl transition duration-200" [routerLink]="['/kontakt']">Kontaktiraj nas</a>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 px-4 hero-img">
          <img class="hero-img lg:absolute top-0 my-12 lg:my-0 h-full w-full lg:w-1/2 rounded-3xl lg:rounded-none object-cover" src="https://ik.imagekit.io/energotoplinarstvo/assets/landing-photo-rijeka.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1649835245280" alt="">
        </div>
    </div>
  </div>
</section>

