// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBhyg1AoqrqPq0A3idWty-tBXx62IQeKBM",
    authDomain: "energo-toplinarstvo.firebaseapp.com",
    projectId: "energo-toplinarstvo",
    storageBucket: "energo-toplinarstvo.appspot.com",
    messagingSenderId: "799548606566",
    appId: "1:799548606566:web:c9cc6701ad4cf663a945a7",
    measurementId: "G-5WT7EZCYP1"
  },
  // NOTE: implicit STATIC app are at port 1668 and hostname endsWith .netlify.app
  static: {
    hosts: [
      'energo-toplinarstvo.com.hr' // @Netlify
    ]
  },
  // NOTE: implicit DYNAMIC app is at ports 4200 and 1864 and hostname endsWith .web.app
  dynamic: {
    hosts: [
      // NOTE: explicit hostname endsWith .web.app is redundant but only defined as placeholder to be as example
      'energo-toplinarstvo.web.app' // @Firebase Hosting
    ]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
