<div class="media-page-wrapper max-w-none" >
  <app-page-header class="w-full" [headerTitle]="'Za medije'"></app-page-header>
  <article class="py-12 px-4" style="color: rgb(54,65,80);">
    <!-- <ng-container *ngIf="true">
      <div class="p-8 pt-0 text-base md:text-2xl text-center">
          Trenutno ne postoje dodatne informacije.
      </div>
    </ng-container> -->

    <div class="container mx-auto flex flex-col items-center" >

      <div class="mt-8 links">

        <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Materijali za medije</b></div>
          <div>preuzmite razne dokumente za medije</div>

          <div *ngIf="isForMediaVisible" class="mb-4 w-full">
            <div class="flex flex-wrap overflow-hidden">

              <div  *ngFor="let forMediaItem of forMedia"
                    class="w-full overflow-hidden border border-gray-200 rounded-lg p-4 mt-4">

                <ng-container *ngIf="forMediaItem.internal" >
                  <a [routerLink]="forMediaItem.internal" >
                    <div class="text-sm font-bold px-2" >
                      {{ forMediaItem.title }}
                    </div>
                  </a>
                </ng-container>

                <ng-container *ngIf="forMediaItem.external" >
                  <a [href]="forMediaItem.external" target="_blank" >
                    <div class="text-sm font-bold px-2" >
                      {{ forMediaItem.title }}
                    </div>
                  </a>
                </ng-container>

              </div>

            </div>
          </div>

          <div>
            <a (click)="isForMediaVisible = !isForMediaVisible" class="text-energo-orange no-underline cursor-pointer">
              <ng-container *ngIf="!isForMediaVisible">
                Preuzmite &rarr;
              </ng-container>

              <ng-container *ngIf="isForMediaVisible">
                &larr; Sakrijte
              </ng-container>
            </a>
          </div>
        </div>


        <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Publikacije</b></div>
          <div>pogledajte sve publikacije vezane za projekt</div>

          <div *ngIf="isPublicationsVisible" class="mb-4 w-full">
            <div class="flex flex-wrap overflow-hidden">

              <div  *ngFor="let publication of publications"
                    class="w-full overflow-hidden border border-gray-200 rounded-lg p-4 mt-4">

                <ng-container *ngIf="publication.internal" >
                  <a [routerLink]="publication.internal" >
                    <div class="text-sm font-bold px-2" >
                      {{ publication.title }}
                    </div>
                  </a>
                </ng-container>

                <ng-container *ngIf="publication.external" >
                  <a [href]="publication.external" target="_blank" >
                    <div class="text-sm font-bold px-2" >
                      {{ publication.title }}
                    </div>
                  </a>
                </ng-container>

              </div>

            </div>
          </div>

          <div>
            <a (click)="isPublicationsVisible = !isPublicationsVisible" class="text-energo-orange no-underline cursor-pointer">
              <ng-container *ngIf="!isPublicationsVisible">
                Preuzmite &rarr;
              </ng-container>

              <ng-container *ngIf="isPublicationsVisible">
                &larr; Sakrijte
              </ng-container>
            </a>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
