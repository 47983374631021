import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sss-leading-numbers',
  templateUrl: './leading-numbers.component.html',
  styleUrls: ['./leading-numbers.component.scss']
})
export class LeadingNumbersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
