import { Component, OnInit } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { AboutPage } from '../../models/about.page.model';
import { PagesContentService } from '../../services/pages/pages-content.service';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
    private content: PagesContentService,
  ) { }

  private about: AboutPage;

  public status: string;
  public title: string;
  public markdownContent: string;
  public markdownContentGoals: string;
  public markdownContentArea: string;
  public markdownContentPurpose: string;
  public publicationUrl = "https://firebasestorage.googleapis.com/v0/b/energo-toplinarstvo.appspot.com/o/flamelink%2Fmedia%2F15122021%20Letak.pdf?alt=media&token=03dab665-3b93-4eff-97c5-ef5ebf417159";

  private isMarkdownContentReadyCounter = 0;


  public onReadyMarkdownContent(): void {
    console.log('about.onReadyMarkdownContent');
    if (this.about) {
      this.isMarkdownContentReadyCounter += 1;
      if (this.isMarkdownContentReadyCounter >= 4) {
        console.log('about.fireManualMyAppReadyEvent');
        this.ims.fireManualMyAppReadyEvent();
      }
    }
  }

  public async ngOnInit(): Promise<void> {

    const about = await this.content.getAbout();
    this.about = about;

    this.status = about.status;
    this.title = about.title;
    this.markdownContent = about.content;
    this.markdownContentGoals = about.content2;
    this.markdownContentArea = about.content3;
    this.markdownContentPurpose = about.content4;

    console.log('about', about);
  }

}
