<!-- <div class="img-wrapper">
    <img alt="" class="img-custom" src="https://ik.imagekit.io/energotoplinarstvo/tr:h-120/assets/logotipi-eu.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648133581971">
</div> -->
<div class="img-wrapper w-full">
    <div class="img-container container py-5 flex energo-container items-center justify-evenly mx-auto">
        <a class="w-1/2 lg:w-max py-1" href="https://strukturnifondovi.hr/hr" target="_blank">
            <img class="w-1/2 lg:w-max logotype" src="https://ik.imagekit.io/energotoplinarstvo/assets/ic-eu-flag.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1649064647921"  />
        </a>
        <a class="w-1/2 lg:w-max py-1" href="https://strukturnifondovi.hr/hr" target="_blank">
            <img class="w-1/2 lg:w-max logotype px-1 md:p-2" src="https://ik.imagekit.io/energotoplinarstvo/assets/ic-eu-fonds.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1649064647843" />
        </a>
        <a class="w-1/2 lg:w-max py-1" href="https://strukturnifondovi.hr/hr">
            <img class="w-1/2 lg:w-max logotype px-2 md:p-2" src="https://ik.imagekit.io/energotoplinarstvo/assets/ic-eu-program.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1649064647959" />
        </a>
        <p class="w-1/2 lg:w-max prose-sm px-1 md:p-2 text-center energo-leading">Projekt je sufinancirala Europska unija<br class="mobile-invisible">iz Europskog fonda za regionalni razvoj</p>
    </div>
</div>
