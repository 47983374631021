<div class="container flex flex-wrap mx-auto -mb-4 md:mb-0">
    <ng-template #loading>
        <div class="flex flex-wrap container py-4 -mx-4 justify-center" >
            <div class="w-full flex flex-col items-center">
            <div class="p-8 pt-0 text-2xl">
                Učitavanje...
            </div>
            </div>
        </div>
    </ng-template>

    <div *ngIf="homePagePosts$ | async as posts; else loading" 
        class="flex flex-wrap container items-center justify-start">
        
        <ng-container *ngIf="!posts?.length">
            <div class="w-full flex flex-col items-center">
            <div class="p-8 pt-0 text-base md:text-2xl text-center">
                Trenutno ne postoje aktivne novosti.
            </div>
            </div>
        </ng-container>

        <ng-container *ngFor="let post of posts; let i = index">
            <div class="w-full md:w-1/{{ posts.length }} px-4 mb-4 md:mb-0">
                <section class="py-10 lg:py-12">
                  <div class="container mx-auto px-4">
                    <div class="max-w-md mx-5">
                      <div class="flex flex-wrap -mx-2">
                        <div class="mb-6 w-full lg:w-1/ px-6">
                          <p class="text-gray-500 leading-loose">{{ moment(post.date).format('DD.MM.yyyy.') }}</p>
                          <h2 class="mb-4 text-xl text-gray-700 font-bold">{{ post.title }}</h2>
                          <p class="text-gray-500 leading-normal"><markdown [data]="post.intro"></markdown></p>
                        </div>
                        <div class="w-full lg:w-auto lg:ml-auto text-center">
                            <a class="inline-block py-2 px-6 bg-yellow-600 hover:bg-yellow-700 text-white font-bold leading-loose rounded-l-xl rounded-t-xl transition duration-200"
                                [routerLink]="['/novosti', post.slug]">Pročitajte više</a></div>
                      </div>
                    </div>
                  </div>
                </section>
            </div>
        </ng-container>
    </div>
  </div>