<div class="links-page-wrapper" >

  <app-page-header class="w-full" [headerTitle]="'Važne poveznice'"></app-page-header>
  <div *ngIf="!links" class="container mx-auto py-20 px-4">
      <div class="flex flex-wrap -mx-4 justify-center" >
          <div class="w-full flex flex-col items-center">
            <div class="p-8 pt-0 text-2xl">
              Učitavanje...
            </div>
          </div>
      </div>
  </div>
  <section class="container mx-auto bg-top bg-no-repeat pt-8">
    <div></div>
  </section>
  <ng-container *ngFor="let link of links;let last = last;" >
    <section class="container mx-auto bg-top bg-no-repeat mt-4">
      <div class="flex flex-wrap lg:mx-40">
        <div class="w-full py-4 px-10 mb-4 md:mb-0">
          <p class="font-bold text-gray-700 text-2xl mb-2 leading-relaxed">{{ link.title }}</p>
          <a class="font-medium text-energo-orange text-xl mb-2 leading-relaxed" href="{{ link.url }}" target="_blank">{{ link.url }}</a>
        </div>
      </div>
    </section>
  </ng-container>
  <section class="container mx-auto bg-top bg-no-repeat pb-8 mb-8">
    <div></div>
  </section>
</div>

